<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--user">
      <div class="view-header">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'users' }"
            round
            grow
            icon="arrow-left"
          />
          <h1 class="page-title">Ajouter un utilisateur</h1>
        </div>
      </div>

      <div class="view-body">
        <Section class="section--styleguide">
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <InputImage
                  textInfo="Fichier au format JPEG et PNG, 1Mo maximum"
                  label="Photo de profil"
                  avatarSize="large"
                  avatarInitials="oss"
                />
              </div>
              <div class="tmp-col col-order-first">
                <Input id="prenom" label="Prénom" required />
                <Input id="nom" label="Nom" required />
                <Input id="email" label="Email" required />
                <Input id="telportable" label="Téléphone" />
              </div>
              <div class="tmp-col col-order-first">
                <SelectExtended
                  id="entite_id"
                  search
                  required
                  label="Entité"
                  optionKey="id"
                  optionValue="nom"
                  apiEndpoint="entite"
                  :apiParams="{
                     sort: 'nom.ASC',
                  }"
                />
                <SelectExtended
                  id="profils"
                  multiple
                  search
                  required
                  chips
                  label="Profil de droits"
                  optionKey="uid"
                  optionValue="designation"
                  apiEndpoint="profil"
                />
                <Radio
                  id="actif"
                  required
                  :items="[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                  ]"
                  label="Actif"
                  inputStyle="inline"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'users' }" text="Annuler" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import InputImage from '@/components/form/InputImage.vue'

export default {
  name: 'UserEditView',
  components: {
    InputImage,
    Input,
    SelectExtended,
    Btn,
    Section,
    Container,
    Radio,
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
      entite_id: this.yup.object().required(),
      profils: this.yup.array().required(),
      prenom: this.yup.string().required(),
      email: this.yup.string().email().required(),
      telportable: this.yup.string().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      data: [],
      entity: [],
      onSubmit,
      errors,
      isSubmitting,
    }
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  methods: {
    handleValues(values) {
      // prepare the data for API
      this.emitter.emit('open-loader')
      const userValues = values
      userValues.entite_id = values.entite_id.key

      this.fetchService.post('utilisateur', values).then(
        (response) => {
          // if user is created then associate profil
          const profils = values.profils.map((profil) => ({ uid: profil.key }))
          this.fetchService
            .put(`utilisateur/${response.data.id}/profil`, { profils })
            .then(
              () => {
                // if all ok send alert and go to liste !
                this.formService.resetForm()
                this.emitter.emit('alert', {
                  type: 'success',
                  content: 'L\'utilisateur a bien été créé.',
                })
                this.emitter.emit('close-loader')
                this.$router.push({ name: 'users' })
              },
              (responseError) => {
                this.formService.handleApiError(responseError)
                this.emitter.emit('close-loader')
              },
            )
        },
        (responseError) => {
          /*
          on laisse formService s'occuper d'afficher les erreurs
          il est possible d'ajouter un message personalisé
          */
          this.formService.handleApiError(responseError, 'Impossible de créer l\'utilisateur')
          this.emitter.emit('close-loader')
        },
      )
    },
  },
}
</script>
